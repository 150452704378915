import React from 'react';
import styled from 'styled-components';
import { RiFacebookFill, RiLinkedinFill, RiInstagramLine, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri'

const FooterWrapper = styled.footer`
    padding: 1rem;
    font-family: 'Noto Serif';
    .credits {
        text-align: center;
        font-size: .75rem;
        margin-top: 1rem;
        clear: both;
    }
    p {
      font-size: .75rem;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      text-align: right;
    }
    li {
      padding: .1rem 0;
    }
    .phone {
      font-family: 'Noto Serif';
      color: var(--blue);
      text-decoration: none;
      font-size: 1rem;
      &:hover {
        text-decoration: underline;
    }
    }
`
const Grid = styled.div`

  width: 90%;
  margin: 0 auto;
  .social-icon {
    color: var(--blue);
    margin: .25rem;
  }
  .social {
      float: none;
      margin: 0 auto;
      padding-bottom: .25em;
      display: table;
  }
    
  }
`

export default function Footer() {
  return (
    <FooterWrapper>
      <Grid>
        <div className='footer'>
          {/* <ul className="footer-menu">
            <li><FooterLink to="/services/">Services</FooterLink></li>
            <li><FooterLink to="/case-studies/">Case Studies</FooterLink></li>
            <li><FooterLink to="/team/">Team Members</FooterLink></li>
            <li><FooterLink to="/experience/">Map of Experience</FooterLink></li>
            <li><FooterLink to="/contact/">Contact Us</FooterLink></li>
          </ul> */}
          <div className="social"> 
            <a class="phone social-icon" href="tel:+16304729661">630-472-9661</a>
            <a className="social-icon" href="https://www.facebook.com/lwcoinc" target="_blank" rel="noreferrer noopener" aria-label="Facebook"><RiFacebookFill /></a>
            <a className="social-icon" href="https://www.linkedin.com/company/ietso-llc/" target="_blank" rel="noreferrer noopener" aria-label="LinkedIn"><RiLinkedinFill /></a>
            <a className="social-icon" href="https://www.instagram.com/lwcoinc/" target="_blank" rel="noreferrer noopener" aria-label="Instagram"><RiInstagramLine/></a>
            <a className="social-icon" href="https://twitter.com/lwcoinc" target="_blank" rel="noreferrer noopener" aria-label="Twitter"><RiTwitterFill/></a>
            <a className="social-icon" href="https://www.youtube.com/channel/UCRzC7pyi_eETqBSbyUHo9Dg" target="_blank" rel="noreferrer noopener" aria-label="Twitter"><RiYoutubeFill/></a>
          </div>
        </div>
      </Grid>
        <p className="credits">&copy; {new Date().getFullYear()} LW&CO | Presidents Plaza - 8700 W Bryn Mawr Ave., Suite 730 | Chicago, IL 60631</p>
    </FooterWrapper>
  );
}
