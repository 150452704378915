import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'

const NavWrapper = styled.div`
  height: 6rem;
  width: 100%;
  border-bottom: 1px solid #DADADA;
  display: relative;
  @media only screen and (max-width: 670px) {
    height: 4rem;
  }
  .logo {
      position: absolute;
      z-index: 2;
      height: 6rem;
      @media only screen and (max-width: 670px) {
        height: 4rem;
      }
    }
  .tagline {
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 6rem;
    color: #0A4268;
    @media only screen and (max-width: 755px) {
      display: none;
    }
  }
`

const StyledLink = styled(Link)`
    font-family: 'Noto Serif';
    font-weight: 700;
    margin: 1.5rem 1.5rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: var(--blue);
    text-decoration: none;
    transition: .2s ease;
    &:focus {
      outline: none;
    }
    &:hover {
      color: var(--light-blue);
    }
    @media only screen and (max-width: 670px) {
      font-size: 1.5rem;
    }
`


class Example extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }
  
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  
  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.setState({menuOpen: false})
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <NavWrapper>

        <Link to="/"><img className="logo" src="/logo.svg" alt="LW&CO logo"/></Link>
        <p className="tagline">INSPIRE others. ENGAGE your mission. SERVE your purpose.</p>
        <Menu 
          right
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <StyledLink onClick={() => this.closeMenu()} id="home" className="menu-item" to="/">Home</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="services" className="menu-item" to="/services/">Services</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="case-studies" className="menu-item" to="/case-studies/">Case Studies</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="clients" className="menu-item" to="/clients/">Client List</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="experience" className="menu-item" to="/experience/">Offices and Map of Experience</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="press" className="menu-item" to="/press/">Press</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="team" className="menu-item" to="/team/">Team</StyledLink>
          <StyledLink onClick={() => this.closeMenu()} id="contact" className="menu-item" to="/contact/">Contact</StyledLink>
        </Menu>
      </NavWrapper>
    );
  }
}

export default Example;