import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'Athena';
  src: local('Athena'), url(../athena.ttf) format('truetype');
}
    :root {
        --blue: #0a4268;
        --light-blue: #AAC4E5;
        --orange: #FBAB61;
        --red: #E0654F;
        font-size: 18px;
    }
    
    /* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 24px;
  top: 36px;
  @media only screen and (max-width: 670px) {
    top: 24px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--blue);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100vh !important;
  width: 100vw !important;
  @media only screen and (min-width: 670px) {
    width: 25vw !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 100vw;
  height: 100vh;
  text-align: right;
  @media only screen and (min-width: 670px) {
    width: 25vw;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`
export default GlobalStyles;