import { createGlobalStyle } from 'styled-components';
import "@fontsource/noto-serif" // Defaults to Weight 400.
import "@fontsource/noto-serif/400.css" // Weight 300.
import "@fontsource/noto-serif/700.css" // Weight 700.
import "@fontsource/poppins/300.css"


const Typography = createGlobalStyle`  
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Athena';
        font-display: swap;
        color: var(--blue);
    }
    h1 {
        font-size: 3rem;
        font-weight: 700;
        @media only screen and (max-width: 670px) {
          font-size: 2.5rem;
        }
    }
    h2 {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 700;
        margin: 1.5rem 0 .5rem 0;
        @media only screen and (max-width: 670px) {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
    }
    h3 {
        font-size: 1.5rem;
        @media only screen and (max-width: 670px) {
          font-size: 1.25rem;
        }
    }
    p {
        font-family: 'Noto Serif';
        line-height: 1.75rem;
        font-size: 1rem;
        margin-top: 0;
        font-display: swap;
        color: #737373;
    }
`

export default Typography;